/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppProps } from 'next/app';
import React from 'react';
import AppProvider from '../src/components/Providers/appProvider';
import { initSentry } from '../src/lib/sentry';
import '../src/styles/globals.css';

initSentry();

const App: React.FC<AppProps & { err: any }> = ({ Component, pageProps, ...props }) => {
  return (
    <AppProvider {...pageProps}>
      <Component {...pageProps} err={props.err || pageProps?.err} />
    </AppProvider>
  );
};

export default App;
