import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import locales, { momentLocales } from '../../../locales';
import { LocaleProvider, LocaleProviderProps } from './localeProvider';
import { getTheme } from '../../styles/theme';
import GlobalStyles from '../../styles/GlobalStyles';

const AppProvider: React.FC<LocaleProviderProps> = ({ children }) => {
  const locale = 'en';
  const messages = useMemo(() => locales[locale], [locale]);
  const momentLocale = useMemo(() => momentLocales[locale], [locale]);

  useEffect(() => {
    if (momentLocale) {
      moment.updateLocale(momentLocale.code, momentLocale.locale);
    }
  }, [momentLocale]);

  return (
    <div id="app-provider">
      <LocaleProvider locale={locale}>
        <IntlProvider locale={locale} messages={messages}>
          <ThemeProvider theme={getTheme}>
            <GlobalStyles />
            {children}
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    </div>
  );
};

export default AppProvider;
